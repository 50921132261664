<template>
  <section id="dashbroad">
    <router-view />
  </section>
</template>

<script>
export default {
}
</script>

<style scoped>
</style>
